export default function AddMonth(date, months) {
// function AddMonth(date, months) {
  let dataSplit = String(date).split('/')

  let dia = parseInt(dataSplit[0])
  let mes = parseInt(dataSplit[1]) + parseInt(months)
  let ano = parseInt(dataSplit[2])

  if(mes > 12) {
    mes -= 12
    ano += 1
  }
  
  dia = String(dia).length < 2 ? (String(0).repeat(2 - String(dia).length) + dia) : dia
  mes = String(mes).length < 2 ? (String(0).repeat(2 - String(mes).length) + mes) : mes

  let dataAjustada = dia + '/' + mes + '/' + ano;
  
  return dataAjustada;
}

// AddMonth('05/12/2021', 1)