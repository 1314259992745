<template>
  <div class="presale q-px-md">

    <!-- LINK DE ACESSO AO CATÁLOGO -->
    <div v-if="!isMobile" class="linkradius row bg-verde q-mt-md">
      <div class="col-11 text-h6">
        <a id="url" :href="urlCatalog">{{ urlCatalog }}</a>
      </div>
      <div class="col-1">
        <q-btn flat text-color="white" icon="far fa-clone" @click="onCopyURL" />
      </div>
    </div>
    <div v-else class="linkradius row justify-center bg-verde q-mt-md">
      <div class="text-subtitle1">
        <a id="url" :href="urlCatalog">{{ urlCatalog }}</a>
      </div>
    </div>

    <!-- TABELA DE ACOMPANHAMENTO -->
    <div class="table q-mt-lg">
      <q-table
        dense
        class="myTable"
        :rows="rows"
        :columns="cols"
        row-key="name"
        :rows-per-page-options="[10, 20, 50, 100, 99999]"
        rows-per-page-label="Linhas por página: "
        :filter="filter"
        no-results-label="Sem dados a serem mostrados"
        no-data-label="Sem dados a serem mostrados"
        @row-click="onEditRow"
      >
        <template v-slot:top-left>
          <q-input filled dense dark v-model="filter" placeholder="Pesquisar" style="width: 150px">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template v-slot:top-right>
          <q-btn
            flat
            rounded
            style="background-color: var(--verdeclaro); color: white"
            icon="file_download"
            @click="exportTable(rows)"
          />
        </template>
      </q-table>
    </div>

    <!-- MODAL PARA PRECIFICAÇÃO DOS ORÇAMENTOS -->
    <q-dialog v-model="modalPreSale" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="title text-h5 text-bold text-center text-verde q-mb-md">
          DETALHES DO ORÇAMENTO
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 60vh;">
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="oneFields" outlined v-model="client" type="text" label="Nome do Cliente" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="threeFields"  outlined v-model="phone" type="text" label="Telefone Contato" />
            <q-input class="threeFields"  outlined v-model="issueDate" type="date" label="Data do Orçamento" />
            <q-select class="threeFields" outlined v-model="status" :options="['PENDENTE', 'ENVIADO AO CLIENTE', 'FINALIZADO', 'CANCELADO']" label="Status" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="oneFields" outlined v-model="address" type="text" label="Endereço do Cliente" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input
              class="oneFields"
              outlined
              v-model="observ"
              type="textarea"
              label="Observações"
              placeholder="
              Exemplo:
                Forma de Pagamento: À Vista (Dinheiro ou PIX) ou em até 3x sem juros no cartão
                Validade: Este orçamento tem validade de 10 dias após sua emissão
                Previsão de Entrega: 15 dias úteis (após confirmação do pagamento)
              "
            />
          </div>
          <div class="q-gutter-y-sm q-mt-md">
            <div
              class="row q-gutter-x-sm"
              v-for="(item, index) in items"
              :key="index"
            >
              <div class="row items-center q-gutter-x-sm">
                <q-img :src="item.url" width="100px" height="100px" />
                <q-input class="col-2" dense v-model="item.product" type="text" label="Produto" />
                <q-input class="col-2" dense v-model="item.brand" type="text" label="Marca" />
                <q-input class="col-2" dense v-model="item.color" type="text" label="Cor" />
                <q-input class="col-1" dense v-model="item.size" type="text" label="Armazenamento" />
                <q-input class="col-1" dense v-model="item.qtdade" type="number" label="Quantidade" />
                <q-input class="col-1" dense v-model="item.cost" type="number" label="Custo" />
                <q-input class="col-1" dense v-model="item.price" type="number" label="Valor" />
              </div>

            </div>
          </div>
        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancel" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="green" label="Salvar Orçamento" @click="onSave" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="warning" label="Gerar Comprovante" @click="onOpenComprovante" />
          <!-- <div class="q-mx-md" /> -->
          <!-- <q-btn outline no-caps color="info" label="Gravar Venda" @click="onSale" /> -->
        </div>
      </div>
    </q-dialog>

    <!-- MODAL PARA PRECIFICAÇÃO DOS ORÇAMENTOS -->
    <q-dialog v-model="modalComprovante" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="title text-h5 text-bold text-center text-verde q-mb-md">
          EMITIR ORÇAMENTO
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />

        <q-scroll-area class="bg-grey" style="width: 100%; height: 60vh;">
          <div class="q-mx-xl q-my-lg">
            <div class="bg-white" id="orcamento">
              <div class="row justify-center items-center q-mx-xl q-py-md">
                <div class="col-2 row justify-center">
                  <q-img :src="logoIMG" height="80px" />
                </div>
                <div class="col-4 row justify-center text-h4 text-bold text-italic">
                  {{ enterprise }}
                </div>
              </div>
              <div>
                <div class="gradeTable row justify-between q-mx-xl">
                  <div class="row col-4">
                    <div class="col-6 bg-verde  text-white q-pl-md">Nº Orçamento</div>
                    <div class="col-6 q-pl-md">{{ editRow.recno }}</div>
                  </div>
                  <div class="row col-4">
                    <div class="col-6 bg-verde text-white q-pl-md">Data Emissão</div>
                    <div class="col-6 q-pl-md">{{ dataEmissao }}</div>
                  </div>
                  <div class="row col-4">
                    <div class="col-6 bg-verde text-white q-pl-md">Telefone Contato</div>
                    <div class="col-6 q-pl-md">{{ phone }}</div>
                  </div>
                </div>
                <div class="gradeTable row q-mx-xl">
                  <div class="col-2 bg-verde text-white q-pl-md">Nome Cliente</div>
                  <div class="col-10 q-pl-md">{{ client }}</div>
                </div>
                <div class="gradeTable row q-mx-xl">
                  <div class="col-2 bg-verde text-white q-pl-md">Endereço</div>
                  <div class="col-10 q-pl-md">{{ address }}</div>
                </div>
              </div>
              
              <div class="text-h5 text-bold text-center q-my-lg">Orçamento de Produtos</div>

              <div class="q-pb-xl">
                <div class="row justify-center items-center">
                  <div class="gradeTableL col-2 bg-verde text-center text-white text-bold">Ilustração</div>
                  <div class="gradeTableC col-1 bg-verde text-center text-white text-bold">Quantidade</div>
                  <div class="gradeTableC col-4 bg-verde text-center text-white text-bold">Descrição</div>
                  <div class="gradeTableC col-2 bg-verde text-center text-white text-bold">Unitário</div>
                  <div class="gradeTableR col-2 bg-verde text-center text-white text-bold">SubTotal</div>
                </div>
                <div class="row justify-center" v-for="(row, index) in editRow.items" :key="index">
                  <div class="gradeTableL row items-center justify-center col-2" style="height: 90px"><q-img :src="row.url" width="80px" height="80px" style="border-radius: 20px;" /></div>
                  <div class="gradeTableC row items-center justify-center col-1" style="height: 90px">{{ row.qtdade }}</div>
                  <div class="gradeTableC row items-center justify-center col-4" style="height: 90px">{{ `${row.product} ${row.brand} (${row.size} - ${row.color})` }}</div>
                  <div class="gradeTableC row items-center justify-center col-2" style="height: 90px">{{ `R$ ${parseFloat(parseFloat(row.price) / parseInt(row.qtdade)).toFixed(2)}` }}</div>
                  <div class="gradeTableR row items-center justify-center col-2" style="height: 90px">{{ `R$ ${parseFloat(row.price).toFixed(2)}` }}</div>
                </div>
                <div class="row justify-center full-width text-subtitle1 text-bold">
                  <div class="col-11 row gradeTable">
                    <div class="col-6 bg-verde text-center text-white q-pl-md">Valor Total</div>
                    <div class="col-6 text-center q-pl-md">{{ `R$ ${totalGeral}` }}</div>
                  </div>
                </div>
                <div>
                  <div class="q-mt-md">
                    <div class="bg-verde text-white text-bold text-center q-mx-xl">Observações</div>
                    <q-input outlined class="q-mx-xl" v-model="observ" type="textarea" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Fechar" @click="modalComprovante = false" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="green" label="Guardar Comprovante" @click="onSaveComprovante" />
        </div>
      </div>
    </q-dialog>

  </div>
</template>

<script>
// import DateFormat from '../../helpers/DateFormat';
import dateNow from '../../helpers/dateNow';
import { mapActions } from 'vuex';
import { Dialog } from 'quasar';
import { defaultColor } from '../../helpers/defaultColor';
import { supabase } from '../../supabase';
import AddMonth from '../../helpers/AddMonth';
import Slug from '../../helpers/Slug'
import * as htmlToImage from 'html-to-image';

export default {
  data() {
    return {
      enterprise: localStorage.getItem('enterprise'),
      modalPreSale: false,
      filter: null,
      cols: [
        { name: 'recno',   align: 'center', label: 'ID',        field: 'recno',   sortable: true },
        { name: 'client',  align: 'center', label: 'Cliente',   field: 'client',  sortable: true },
        { name: 'phone',   align: 'center', label: 'Telefone',  field: 'phone',   sortable: true },
        { name: 'status',  align: 'center', label: 'Status',    field: 'status',  sortable: true },
        { name: 'dt',      align: 'center', label: 'Data',      field: 'dt',      sortable: true },
      ],
      rows: [],

      //~> PARA A URL DO CATÁLOGO
      urlCatalog: null,

      //~> PARA O MODAL
      editRow: null,
      recno: null,
      client: null,
      phone: null,
      issueDate: null,
      status: null,
      items: null,
      address: null,
      cost: 0,
      price: 0,
      comprovante: false,
      observ: null,

      //~> PARA O COMPROVANTE
      modalComprovante: false,
      logoIMG: null,
      dataEmissao: null,
      totalGeral: 0,
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
    dataAjustada() {
      return AddMonth(this.dataEmissao, 3)
    }
  },
  methods: {

    //~> REVER TODO O FLUXO DE INCLUSÃO DO ORÇAMENTO NA TELA DE VENDAS
    //~> Pensar numa forma de inserir essas vendas diferenciando oq seria Baixa Estoque e Sob Encomenda
    //~> Em ultimo caso não conseguindo achar uma solução, retirar a opção de GRAVAR VENDA

    ...mapActions('Querys', [ 'insertData', 'updateData' ]),
    async onCopyURL() {

      await navigator.clipboard.writeText(this.urlCatalog)

      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Link copiado com sucesso!',
        cancel: 'OK',
        ok: false
      })
    },
    onCancel() {
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente sair? Ao sair o preenchimento será perdido.',
        persistent: true,
        cancel: true,
      }).onOk(() => {
        this.modalPreSale = false;
      })
    },
    async onSave() {
      if(this.comprovante) {
        this.status = 'ENVIADO AO CLIENTE'
      }

      try {
        await this.updateData({
          table: 'GE_PRE_SALE',
          fields: {
            'GE_ADDRESS': this.address,
            'GE_CLIENT': this.client,
            'GE_ITEMS': this.editRow.items,
            'GE_PHONE': this.phone,
            'GE_STATUS': this.status,
            'GE_DATE_ISSUE': this.issueDate,
            'GE_OBS': this.observ,
          },
          match: {
            'RECNO': this.editRow.recno
          }
        })

        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Orçamento alterado com sucesso!'
        });

        this.modalPreSale = false;
        this.executeQuery();
      } catch (error) {
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: error.message
        });
      }
    },
    onOpenComprovante() {
      this.modalComprovante = true

      let priceTotal = 0
      this.editRow.items.forEach(row => {
        priceTotal += parseFloat(row.price)
      });

      this.totalGeral = parseFloat(priceTotal).toFixed(2);
    },
    async executeQuery() {
      //~> PARA RETORNAR DADOS DA TABELA
      const { data, error } = await supabase
        .from('GE_PRE_SALE')
        .select('*')
        .filter('GE_ENTERPRISE', 'eq', Slug(this.enterprise))
        .eq('DELETED', ' ')

      if(error) { console.error(error.message); }
      if(data) {
        this.rows = data.map((pre) => {

          let data = String(pre.GE_DATE_ISSUE).split('-')
          let dia = String(data[2]).length < 2 ? '0' + data[2] : data[2]
          let mes = String(data[1]).length < 2 ? '0' + data[1] : data[1]
          let ano = data[0]

          return {
            dt: `${dia}/${mes}/${ano}`,
            recno: pre.RECNO,
            productHearth: pre.GE_TEAM_HEARTH,
            status: pre.GE_STATUS,
            phone: pre.GE_PHONE,
            items: pre.GE_ITEMS,
            client: pre.GE_CLIENT,
            address: pre.GE_ADDRESS,
            obs: pre.GE_OBS,
          }
        });
      }

      //~> PARA RETORNAR A URL PÚBLICA DA LOGO
      const urlImage = await supabase
        .storage
        .from('logos')
        .getPublicUrl(Slug(this.enterprise) + '.png')

      this.logoIMG = urlImage.data.publicURL

    },
    onEditRow(_, row) {
      this.comprovante = false
      this.editRow = row

      let data = String(row.dt).split('/')
      let dia = String(data[0]).length < 2 ? '0' + data[0] : data[0]
      let mes = String(data[1]).length < 2 ? '0' + data[1] : data[1]
      let ano = data[2]

      this.client = row.client
      this.phone = row.phone
      this.issueDate = `${ano}-${mes}-${dia}`
      this.status = row.status
      this.items = row.items
      this.address = row.address
      this.recno = row.id
      this.observ = row.obs

      this.dataEmissao = dia + '/' + mes + '/' + ano
      this.modalPreSale = true;
    },
    async getCategory(category) {
      category = 'brasileirao'
      const { data } = await supabase
        .from('depara_Category')
        .select('*')
        .filter('catalogo', 'eq', category)
        .single()

      return data.sistema;
    },
    async onSale() {
      const data = dateNow()
      let codControl = Math.random().toFixed(8);

      try {
        this.items.forEach(async item => {
          let category = await this.getCategory(item.category)

          await this.insertData({
            table: 'GE_SELLS',
            fields: {
              'GE_CLIENT': this.client,
              'GE_LEAGUE': category,
              'GE_TEAM': item.product,
              'GE_MODEL': item.brand,
              'GE_SIZE': item.size,
              'GE_GENDER': item.color,
              'GE_QUANTITY': item.qtdade,
              'GE_SALEVALUE': item.price,
              'GE_RECEIVEDVALUE': item.price,
              'GE_RECEIVABLE': 0,
              'GE_COST': item.cost,
              'GE_GAIN': parseFloat(parseFloat(item.price) - parseFloat(item.cost)).toFixed(2),
              'GE_DATEPURCHASE': parseInt(String(data).replaceAll('-', '')),
              'GE_TRACKINGCODE': '',
              'GE_PURCHASERECEIVED': 'Não',
              'GE_DOWNSTOCK': 'Não',
              'GE_ENTERPRISE': this.enterprise,
              'GE_OBS': '',
              'GE_COD_CONTROL': codControl,
              'DELETED': ' ',
            }
          })
        });

        await this.insertData({
          table: 'GE_CLIENTS',
          fields: {
            'GE_NAME': this.client,
            'GE_PHONE': this.phone,
            'GE_ADDRESS': this.address,
            'GE_TEAMHEART': this.productHearth,
            'GE_BIRTHDAY': 19000101,
            'GE_ENTERPRISE': this.enterprise,
            'DELETED': ' ',
          }
        })

        await this.updateData({
          table: 'GE_PRE_SALE',
          fields: {
            'GE_ADDRESS': this.address,
            'GE_CLIENT': this.client,
            'GE_ITEMS': this.editRow.items,
            'GE_PHONE': this.phone,
            'GE_STATUS': 'FINALIZADO',
            'GE_DATE_ISSUE': this.issueDate,
            'GE_OBS': this.observ,
          },
          match: {
            'RECNO': this.editRow.recno
          }
        })
      } catch (error) {
        console.error(error);
      }

      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Orçamento gravado nas tabelas de vendas!',
        cancel: 'OK',
        ok: false,
      })

      this.modalPreSale = false;
    },
    onBudget() {
      this.comprovante = true;
    },
    onSaveComprovante() {
      htmlToImage.toPng(
        document.querySelector('#orcamento'),
        { quality: 1 }
      ).then(dataURL => {
          var link = document.createElement('a');
          link.download = `orcamento_produtos.png`;
          link.href = dataURL;
          link.click();
        })
    },
    exportTable(aTable) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(aTable[0]).join(";"),
        ...aTable.map(item => Object.values(item).join(";"))
        ].join("\n")
        .replace(/(^\[)|(\]$)/gm, "")
        .replaceAll("ç", "c")
        .replaceAll("ã", "a")
        .replaceAll("â", "a")
        .replaceAll("á", "a")
        .replaceAll("à", "a")
        .replaceAll("ê", "e")
        .replaceAll("é", "e")
        .replaceAll("í", "i")
        .replaceAll("õ", "o")
        .replaceAll("ô", "o")
        .replaceAll("ú", "u");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "gestao_Orcamentos.csv");
      link.click();
    },
  },
  created() {
    defaultColor();
    this.executeQuery();

    const url = window.location.href;
    const slug = Slug(this.enterprise);
    let linkCatalog = String(url).replace('/preSales', `/catalog/${slug}`);

    this.urlCatalog = linkCatalog;
  }
}
</script>

<style lang="scss">
  @import url('../../globais/GlobalStyles.scss');

  .linkradius {
    border-radius: 5px;
  }

  #url {
    text-decoration: none;
    color: #fff;
  }
</style>